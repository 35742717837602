import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Routes from "../../constants/Routes"
import { FaGlobeAmericas, FaMountain, FaSearch } from "react-icons/fa"
import { AiOutlineSafety } from "react-icons/ai"
import SimpleExternalButton from "../buttons/SimpleExternalButton"
import { StaticImage } from "gatsby-plugin-image"
import Variables from "../../constants/Variables"
import { GiCampingTent } from "react-icons/gi"

const NavBar: React.FunctionComponent = () => {
  const { site } = useStaticQuery<any>(
    graphql`
      query navbar {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const { title } = site.siteMetadata
  const [isExpanded, toggleExpansion] = useState(false)
  const [dropdownExpanded, setDropdownExpanded] = useState(false)

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white theme-border-bottom">
          <div className="container px-2 md:px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto px-0 lg:static lg:block lg:justify-start">
              <Link to="/" title={title}>
                <StaticImage
                  src="../../images/logo/logo-green-cropped.png"
                  alt="Alper Logo"
                  placeholder="blurred"
                  className="w-8 h-8 inline-block mt-1 ml-0"
                />
                <span
                  style={{
                    fontFamily: Variables.fontFamilyHeadline,
                  }}
                  className="text-2xl ml-2 text-theme leading-10"
                >
                  {title}
                </span>
              </Link>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <span className="block relative w-6 h-px rounded-sm bg-theme" />
                <span className="block relative w-6 h-px rounded-sm bg-theme mt-1" />
                <span className="block relative w-6 h-px rounded-sm bg-theme mt-1" />
              </button>
            </div>
            <div
              className={`${
                isExpanded ? `block` : `hidden`
              } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
              id="example-navbar-info"
            >
              <ul className="flex flex-col lg:flex-row list-none ml-auto">
                <li className="nav-item">
                  <Link
                    className="px-0 md:px-6 pt-8 md:pt-3 pb-2 flex items-center leading-snug text-theme hover:opacity-50"
                    to={Routes.COUNTRIES}
                    activeClassName="active-nav"
                    title="Countries"
                  >
                    <FaGlobeAmericas
                      size={14}
                      className="mr-2 text-medium"
                      style={{ marginBottom: 2 }}
                    />
                    Countries
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="px-0 md:px-6 pt-8 md:pt-3 pb-2 flex items-center leading-snug text-theme hover:opacity-50"
                    to={Routes.PARK_TYPES}
                    activeClassName="active-nav"
                    title="Park Types"
                  >
                    <FaMountain
                      size={14}
                      className="mr-2 text-medium"
                      style={{ marginBottom: 2 }}
                    />
                    Park Types
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="px-0 md:px-6 pt-8 md:pt-3 pb-5 md:pb-2 flex items-center leading-snug text-theme hover:opacity-50"
                    to={Routes.SEARCH}
                    activeClassName="active-nav"
                    title="Search"
                  >
                    <FaSearch
                      size={16}
                      className="mr-2 text-medium"
                      style={{ marginBottom: 2 }}
                    />
                    Search
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="px-0 md:px-6 pt-8 md:pt-3 pb-5 md:pb-2 flex items-center leading-snug text-theme hover:opacity-50 cursor-pointer"
                    onClick={() => setDropdownExpanded(!dropdownExpanded)}
                  >
                    <GiCampingTent
                      size={18}
                      className="mr-2 text-medium"
                      style={{ marginBottom: 0 }}
                    />
                    Gear Guides
                  </a>
                  <div
                    className={`bg-white text-base z-99 list-none divide-y divide-gray-100 rounded shadow my-4 md:absolute ${
                      !dropdownExpanded ? "hidden" : ""
                    }`}
                  >
                    <ul className="py-1" aria-labelledby="dropdown">
                      <Link
                        className="px-6 pt-2 md:pt-3 pb-2 md:pb-2 flex items-center leading-snug hover:opacity-50"
                        to={Routes.DOWN_JACKET_GUIDE}
                        activeClassName="active-nav"
                        title="Down Jacket Guide"
                      >
                        Down Jacket Guide
                      </Link>
                      <Link
                        className="px-6 pt-2 md:pt-3 pb-2 md:pb-2 flex items-center leading-snug hover:opacity-50"
                        to={Routes.TENT_GUIDE}
                        activeClassName="active-nav"
                        title="1-Person Tent Guide"
                      >
                        1-Person Tent Guide
                      </Link>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <SimpleExternalButton
                    link={Routes.APP_IOS}
                    text="Download app"
                    className="mr-0 md:mr-4 ml-0 md:ml-6 mt-4 md:mt-0"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NavBar
